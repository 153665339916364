import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, ReactiveFormsModule, NgForm} from "@angular/forms";

import {injectStripe, NgxStripeModule, StripeService} from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions, StripePaymentElement
} from '@stripe/stripe-js';
import {CommonModule} from "@angular/common";
import {HttpClient, HttpParams} from "@angular/common/http";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {StripeElementsOptionsMode} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-payment-test',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent {
  @ViewChild('paymentFormElement') paymentFormElement: NgForm;
  @Output() onPaymentDone = new EventEmitter<any>
  @Output() onPaymentChange = new EventEmitter<any>
  elements: StripeElements;
  card: any;
  stripe = injectStripe(environment.stripeKey);
  paymentData: any = null;
  paymentType: any = '';

  @Input() set makePayment(value: any){
   // this.paymentData = value;
    if(value){
      this.elements.submit();
      this.paymentFormElement.ngSubmit.emit();
    }
  }
  @Input() set payment(value: any){
    // this.paymentData = value;
    if(value){
      this.paymentType = value.type === 'one_time' ? 'payment' : 'subscription';
      this.paymentData = value;
    }
  }

  elementsOptions: StripeElementsOptionsMode = {
    locale: 'tr',
    currency: 'usd',
    amount: 0,
    payment_method_configuration: environment.payment_method_configuration,
    mode: this.paymentType,
    appearance : {
      theme: 'stripe',
      variables: {
        colorPrimary: '#01c9ff',
        colorBackground: '#ffffff',
        colorText: '#060533',
        colorDanger: '#ff0000ff',
      }
    }
  };

  stripeTest: FormGroup;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    this.elementsOptions.mode = this.paymentType;
    this.paymentType = this.paymentData.type === 'one_time' ? 'payment' : 'subscription';
    this.elementsOptions.amount = this.paymentData.type === 'one_time' ? this.paymentData.receipt.paymentSummary.subTotal * 100 : this.paymentData.receipt.installmentSummary.subTotal * 100;
    let elements = this.stripe.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements
       // if (!this.card) {
          this.card = this.elements.create('payment');
          this.card.mount('#payment-element');
          this.card.addEventListener('change', (event: any) => {
            this.onPaymentChange.emit(event);
          });
      //  }
      });
  }
  ngOnDestroy(){

  }


  onPaymentSubmit(){
  }
  pay(form:any, secret:string) {
    const params = new HttpParams().set('email', form.email)
    let returnUrl = environment.url + this.router.url + '?' +  params.toString();
    this.stripe
      .confirmPayment({
        elements: this.elements,
        clientSecret: secret,
        confirmParams: {
          return_url: returnUrl,
          payment_method_data: {
            billing_details: {
              name: form?.firstName + ' ' + form?.lastName,
              email: form?.email
            }
          }
        },
        redirect: 'if_required'
      })
      .subscribe(result => {
          this.onPaymentDone.emit(result);
      });
  }
  createToken() {
    // @ts-ignore
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }
}
