import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-complete-purchase',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './complete-purchase.component.html',
  styleUrl: './complete-purchase.component.scss'
})
export class CompletePurchaseComponent {
  @Input() set reviewData(value: any){
    this.paymentData = value;
  }
  @Input() customerData: any
  paymentData: any;
  constructor() {

  }
  ngAfterViewInit() {
  }
}
