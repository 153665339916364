<div class="col-12 purchase-card ">
  <p>Profile Summary</p>
  <div class="card-row-holder">
    <p> First Name</p>
    <p> {{paymentData?.profile?.firstName}}</p>
  </div>
  <div class="card-row-holder">
    <p> Name</p>
    <p> {{paymentData?.profile?.lastName}}</p>
  </div>
  <div class="card-row-holder">
    <p> Email</p>
    <p> {{paymentData?.profile?.email}}</p>
  </div>
  <div class="card-row-holder">
    <p> Phone</p>
    <p> +38344123456</p>
  </div>
</div>

<div class="col-12 purchase-card ">
  <p>Payment Summary</p>
  <div class="card-row-holder">
    <p> Price</p>
    <p> <b>${{paymentData?.paymentSummary?.price}}</b></p>
  </div>
  <div class="card-row-holder">
    <p> Selected Payment Option</p>
    <p> Card</p>
  </div>
  <div class="card-row-holder fee">
    <p> <i>Processing Fee</i></p>
    <p><i>${{paymentData?.paymentSummary?.processingFee}}</i></p>
  </div>
  <div class="card-row-holder">
    <p> Total Due now</p>
    <p> <b>${{paymentData?.paymentSummary?.totalPrice}}</b></p>
  </div>
</div>
