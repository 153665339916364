import {Component, inject, ViewChild} from '@angular/core';
import {CommonModule, NgClass, NgIf} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {Subject} from "rxjs";;
import {PaymentPlan} from "../models/paymentPlan";
import {PaymentService} from "../services/payment.service";
import {CompletePurchaseComponent} from "../complete-purchase/complete-purchase.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {environment} from "../../environments/environment";
import {MatExpansionModule} from "@angular/material/expansion";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatDialog} from "@angular/material/dialog";
import {TermConditionsComponent} from "../shared/term-conditions/term-conditions.component";
import {PaymentComponent} from "../tests/payment-test/payment.component";
import {Order} from "@stripe/stripe-js";
import Payment = Order.Payment;

import {HttpClient, HttpClientModule, HttpParams} from "@angular/common/http";
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {Payments} from "../models/payments";
import {RedirectComponent} from "../redirect/redirect.component";
import {Customer} from "../models/customer";
import {RecaptchaV3Module, ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-personal-details',
  standalone: true,
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule, NgClass, NgIf, MatInputModule, MatIconModule, MatSelectModule, CompletePurchaseComponent, MatProgressSpinnerModule, MatExpansionModule, MatButtonModule, PaymentComponent, MatChipsModule,
    RedirectComponent, RecaptchaV3Module],
 // providers: [ReCaptchaV3Service],
  templateUrl: './personal-details.component.html',
  styleUrl: './personal-details.component.scss'
})
export class PersonalDetailsComponent {
  step: number =1;
 // @ViewChild(PaymentElementComponent) private paymentElement: PaymentElementComponent;
  @ViewChild(PaymentComponent) private paymentElement: PaymentComponent;

  form: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]),
    referralSource: new FormControl(''),
    parent: this.formBuilder.group({
      firstName: new FormControl('', ),
      lastName: new FormControl('', ),
      email: new FormControl('', [Validators.email]),
      phoneNumber: new FormControl('', [ Validators.pattern('[- +()0-9]+')]),
    }),
  });
  paymentPlanIdSelected = null;
  paymentPlanItems: PaymentPlan[];
  customerId = null;
  paymentId = null;
  paymentType = null;
  paymentData: any = null;
  reviewData:any = null;
  customerData: Customer = {} as Customer;
  paymentSecret: any = null;
  loading = false;
  selectedItem: any = null;
  programData:any = [];
  makePayment:boolean = false;
  termsChecked = false;
  isPaying:boolean = false;
  paymentMethod: string = 'card';
  paymentSummary: any = null;
  stripeLoaded = false;
  isForTeens = false;
  purchaseId: any = null;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  coupons: any[] = [];
  showInputField: boolean = false;
  showIHaveCouponLink: boolean = false;
  announcer = inject(LiveAnnouncer);
  errorMessage = '';
  isValidCoupon: boolean = false;
  couponAmount: number = 0;
  upfrontAmountAfterCoupon: number;
  installmentAmountAfterCoupon: number;
  redirecting: boolean = false;
  hasCoupon: boolean = false;
  nextButtonDisabled = false;
  /**
   * Constructor
   * @param formBuilder
   * @param router
   * @param recaptchaV3Service
   * @param route
   * @param http
   * @param dialog
   * @param paymentService
   */
  constructor(private formBuilder: FormBuilder,
              private router: Router,
            //  private recaptchaV3Service: ReCaptchaV3Service,
              private route: ActivatedRoute,
              private http: HttpClient,
              private dialog: MatDialog,
              private paymentService: PaymentService) {
  }

  ngOnInit(){
    let url = this.router.url;
    url = url.replace(/\//g, '').toLowerCase();
    this.getPlanDetails(url);
    const clientSecret: any = this.route.snapshot.queryParams['payment_intent_client_secret'];
    if(clientSecret){
       this.redirectAfterAffirmPayment();
      }
  }

  ngOnChanges(){
  }

  resolved(captchaResponse: string | null) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

/*  public executeImportantAction(): void {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
       // console.log('This is your token: ', token);
        this.sendCaptcha(token)
      });
  }*/

/*  sendCaptcha(token: string){
    this.http.post(`https://recaptchaenterprise.googleapis.com/v1/projects/my-project-13376-1709585404986/assessments?key=AIzaSyDoJ4mhEbjpSPOiHNl9GPxLsWweoAO-oUU`, {
      event: {
      token: token,
      expectedAction: "USER_ACTION",
      siteKey: "6Ld_ao4pAAAAALHmYRrcILju-_xvtf4p2c42tqPd",
    }
    })
      .subscribe((result: any) => {
        //console.log('res', result);
        if(result.riskAnalysis?.score > 0.5){
          console.log('score',result.riskAnalysis?.score);
          this.submitForm();
        }
      });
  }*/

  hideCouponLink(): void {
   // this.showIHaveCouponLink = false;
    this.showInputField = true;
  }

  redirectAfterAffirmPayment(){
    this.redirecting = true;
  }
  hasCouponApplied(item: any) {
    let value = false;
      for (let coupon of this.coupons) {
         value= coupon?.priceId === item?.priceId;
      }
    return value;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (this.coupons.length > 0) {
   //   return;
    }
    if (value) {
      this.validateCoupon(value);
      if (!this.isValidCoupon ){
        return;
      }
      this.showInputField = false;
     // this.showIHaveCouponLink = false; // Hide the link

    }
    event.chipInput!.clear();
  }

  hide(): void {
    this.coupons = []; // Clear all chips
    this.showInputField = false; // Hide the input field
    //this.showIHaveCouponLink=true;
  }

  remove(item: any): void {
    const index = this.coupons.indexOf(item);
    if (index >= 0) {
      this.coupons.splice(index, 1);
      this.isValidCoupon=false;
      this.installmentAmountAfterCoupon;
      this.upfrontAmountAfterCoupon;
      this.announcer.announce(`Removed ${item}`);
      this.showInputField = false;
      if (this.coupons.length === 0) {
       // this.showIHaveCouponLink = false;
        this.showInputField = true;
      }
    }
  }

  validateCoupon(couponValue: string): void {
    this.errorMessage='';
    const requestData = { "promoCode": couponValue, priceId: this.paymentPlanIdSelected };
    this.http.post(environment.shopUrl + '/plans/promo-code', requestData)
      .subscribe({
        next: (result: any) => {
          if (result.data.isValid) {
            this.isValidCoupon = true;
            this.coupons = [];
            this.coupons.push({ name: couponValue , priceId: result.data.priceId});
            this.showInputField = false;
            this.couponAmount = result.data.couponDiscount;
            this.installmentAmountAfterCoupon = result.data.receipt?.installmentSummary?.subTotal;
            this.upfrontAmountAfterCoupon = result.data.receipt?.paymentSummary?.subTotal;

          } else {
            this.errorMessage = result.data.message;
            this.isValidCoupon = false;
            this.displayErrorMessage(this.errorMessage);
          }
        },
        error: (err) => {
          console.error('Error validating coupon:', err);
        }
      });
  }

  displayErrorMessage(msg: string): void {
    this.errorMessage = msg;
    // You may also want to trigger change detection if necessary
  }

getPlanDetails(code: string){
    this.http.get(`${environment.shopUrl}/products/${code}`, {
    })
      .subscribe((result: any) => {
        if(!result.data){
          this.redirectToExternal();
        }
        this.programData = result.data;
        this.getPaymentPlans();
        this.isForTeens = result.data?.isForTeens
      },
        (error) => {
        console.log('Error', error);
        this.redirectToExternal();
        });
  }

  /**
   * Redirect to external site 'cydeo.com'
   */
  redirectToExternal(){
    window.location.href ='https://bilginomist.com/';
  }
  getPaymentPlansForUser(email: any){
    const inputElement = email.target as HTMLInputElement;
    const inputValue = inputElement.value;
    this.http.get(`${environment.shopUrl}/products/${this.programData.lmsProgramId}/prices?`, {params: new HttpParams().set('email', inputValue)
    })
      .subscribe((result: any) => {
        this.paymentPlanItems = result.data.prices;
        for(let coupon of this.paymentPlanItems){
          if(coupon.allowCoupons){
            this.hasCoupon = true;
          }
        }
      });
  }

  getPaymentPlans(){
    this.http.get(`${environment.shopUrl}/products/${this.programData.lmsProgramId}/prices`, {
    })
      .subscribe((result: any) => {
        this.paymentPlanItems = result.data.prices;
        for(let coupon of this.paymentPlanItems){
          if(coupon.allowCoupons){
            this.hasCoupon = true;
          }
        }
      });
  }

  submitForm(){
    if(this.form.invalid){
      return;
    }
    this.nextButtonDisabled = true;
    let customer = this.form.getRawValue();
    this.customerData = this.form.getRawValue();
    customer.email = customer.email.toLowerCase();
    customer.parent.email = customer.parent.email.toLowerCase();
    delete customer['referralSource'];
    delete customer['referrerFullName'];

    this.http.post(`${environment.shopUrl}/purchases`, {
      customer: customer,
      referralSource: this.form.getRawValue().referralSource,
      referrerFullName: this.form.getRawValue().referrerFullName,
      product: {
        lmsProgramCode: this.programData.lmsProgramCode,
        lmsProgramId: this.programData.lmsProgramId,
        lmsProgramName: this.programData.lmsProgramName,
      }
    })
      .subscribe((result: any) => {
        this.nextButtonDisabled = false;
        this.nextStep(2);
        this.purchaseId = result.data.purchaseId;
        this.customerId = result.data.customer.customerId;
      });
  }

  setDefaultPaymentPlan(){
    this.paymentPlanItems.forEach((item: any) => {
      if(item.type == 'one_time'){
        this.paymentPlanIdSelected = item.priceId;
      }
    } );
  }

  updateSelectedPlan(priceId: string){

    //this.paymentService.setPayment('');
 //   this.paymentSecret = null;
  //  this.loading = true;
/*    this.http.post(`${environment.shopUrl}/plans`, {
      customerId: this.customerId,
      programId: this.programData.lmsProgramId,
      priceId: priceId
    })
      .subscribe((result: any) => {
        this.paymentSecret = result.data.paymentKey;
        this.paymentService.setPayment(result.data.paymentKey);
        this.loading = false;
        this.paymentId = result.data.paymentId;
      });*/
  }
  onRedirectAfterAffirm(){
    this.step = 4;
     this.customerData.email = this.route.snapshot.queryParams['email'];
    this.redirecting = false;
  }
  onPaymentChange(event: any){
    this.isPaying = false;
    setTimeout(() => {
      this.stripeLoaded = true;
    }, 800);
    this.paymentMethod = event.value?.type;
    this.getReviewInfo();
  }


  /**
   * To be triggered when payment is successful;
   */
  onPaymentDone(event:any){
    this.isPaying = false;
    if(event.paymentIntent?.status === 'succeeded'){
      this.nextStep(4);
    this.paymentService.setPayment('');
    this.http.post(`${environment.shopUrl}/plans/success`, {
      customerId: this.customerId,
      paymentId: this.paymentId,
      paymentType: this.paymentType,
      priceId: this.paymentPlanIdSelected,
      programCode: this.programData.lmsProgramCode
    })
      .subscribe((result: any) => {
      });
    } else {
      alert('Something went wrong');
    }
  }

  /**
   * Get review info for the payment;
   */
  // getReviewInfo(){
  //   let requestBody = {
  //     customerId: this.customerId,
  //     priceId: this.paymentPlanIdSelected,
  //     paymentMethod: this.paymentMethod,
  //     coupon: ''
  //   };
  //
  //   if (this.isValidCoupon && this.coupons && this.coupons.length > 0) {
  //     requestBody['coupon'] = this.coupons[0];
  //   }
  //
  //   this.http.post(`${environment.shopUrl}/plans/review`, requestBody)
  //     .subscribe((result: any) => {
  //       this.paymentSummary = result;
  //       console.log('ARIAN:' + result);
  //
  //     });
  // }

  getReviewInfo(){
    let promocode= '';

    let body:any ={
      customerId: this.customerId,
      priceId: this.paymentPlanIdSelected,
      paymentMethod: this.paymentMethod,
    }
    if(this.paymentPlanIdSelected === this.coupons[0]?.priceId){
      promocode = this.coupons[0]?.name;
      body['coupon'] = promocode;
    }
    this.http.post(`${environment.shopUrl}/plans/review`, {
      ...body
    })
      .subscribe((result: any) => {
        this.paymentSummary = result.data;
      });
  }

  openTermsConditions(){
    const dialogRef = this.dialog.open(TermConditionsComponent, {
      data: { programCode: this.programData.lmsProgramCode }
    });
  }

  /**
   * Next step
   * @param step
   */
  nextStep(step: number){
    this.step = step;
    if(step ===3){
      this.choosePlan();
    }
  }
  backStep(){
    if(this.step > 1){
      this.step--;
    }
  }
  pay(){
    let promocode= '';
    if(this.selectedItem.priceId === this.coupons[0]?.priceId){
      promocode = this.coupons[0]?.name;
    }

    let body:any ={
      customerId: this.customerId,
      programId: this.programData.lmsProgramId,
      priceId: this.selectedItem?.priceId,
      paymentMethod: this.paymentMethod,
      purchaseId: this.purchaseId,
      isAccepted: this.termsChecked
    }
    if(this.selectedItem.priceId === this.coupons[0]?.priceId){
      promocode = this.coupons[0]?.name;
      body['promoCode'] = promocode;
    }

    this.isPaying = true;
    this.makePayment = true;
       this.http.post(`${environment.shopUrl}/plans`, {
      ...body
    })
      .subscribe((result: any) => {
        this.paymentSecret = result.data.paymentKey;
        this.paymentService.setPayment(result.data.paymentKey);
        this.loading = false;
        this.paymentId = result.data.paymentId;
        this.paymentElement.pay(this.form.getRawValue(), this.paymentSecret);
        this.makePayment = false;
        this.sendPurchaseAgreement(result.data.paymentKey);
      });

  }

  sendPurchaseAgreement(purchaseId: string){
    this.http.post(`${environment.shopUrl}/purchases/agreement`, {
      purchaseId: this.purchaseId,
      isAccepted: this.termsChecked,
      priceId: this.paymentPlanIdSelected,

    }).subscribe((result: any) => {
    });
  }

  choosePlan(){
    let item: any = this.selectedItem;
    this.updateSelectedPlan(item.priceId);

  }

  itemSelected(item:any){
    this.selectedItem = item;
    this.paymentPlanIdSelected = item.priceId;
  //  this.getReviewInfo();
    this.reviewData = item;
    this.paymentType = item.type;
    this.paymentData = item;
    this.showInputField = false;
    this.errorMessage = '';
 //   if (!this.showInputField && !this.isValidCoupon) {
      this.showIHaveCouponLink = item.allowCoupons;
 //   }
  }


}
