<div class="redirect-cmp" *ngIf="redirecting">
  <app-redirect
    (onRedirect)="onRedirectAfterAffirm()"
  ></app-redirect>
</div>
<div class="container-fluid" *ngIf="!redirecting">
  <div class="row sub-container">
    <div class="mobile-header">
      <div class=" mobile-header-holder">
        <div class=" mobile-header-left">
          <img src="assets/images/logo-bilginomist.webp">
        </div>
        <div class=" mobile-header-right">
          <p>Güvenli Ödeme </p>
        </div>
      </div>


    </div>
    <div class="left-overlay">
     <div class=" left">

      <!--  <img src="assets/images/logo.svg">-->
      <div class=" left-main">
        <div class="col-12 title-holder1" style="margin-top: 100px;">
          <p class="checkout-title"> <img style="margin-top: -4px; margin-left: 10px; width: 300px"
                                          src="assets/images/logo-bilginomist.webp">
          </p>
        </div>
        <span style="padding-left: 10px;">Güvenli ödeme</span>
        <div class="program-details" style="">
          <span style="padding-left: 10px;">Program</span>
          <p class="course-name"><a href="{{programData.productLink}}" target="_blank"> {{programData?.lmsProgramName}} </a></p>
        </div>


      </div>
      <div class="row footer-row">
        <div class="details-footer">
          <a href="https://bilginomist.com/" target="_blank"><img style="max-height: 30px;" src="assets/images/logo-bilginomist.webp"></a>
        </div>
      </div>

    </div>
  </div>
    <div class="col right">
      <div class="mobile-image" *ngIf="step === 1">
        <div class="mobile-image-overlay">
          <div class="program-details" style="">
            <span style="padding-left: 10px; color: #fff;">Program</span>
            <p class="course-name"><a href="{{programData.productLink}}" target="_blank"> {{programData?.lmsProgramName}} </a></p>
          </div>
        </div>
      </div>

      <div class="stepper">
        <div class="stepper-horizontal d-flex " id="stepper1">
          <div class="step " style="padding-left: 0;" *ngIf="step === 1 || 2" [ngClass]="step > 1 ? 'done' : 'editing'">
            <div class="step-circle"><span>1</span></div>
            <div class="step-title">Başvuruyu Başlat</div>
            <div class="step-bar-left" style="width: calc(100% - 1rem); margin-left: 2px;"></div>
            <div class="step-bar-right" style="margin-left: 2px;     width: calc(100% - 1rem);"></div>
          </div>
          <div class="step" *ngIf="step === 1 ||   2 || 3"
               [ngClass]="{'done': step > 2, 'editing' : step === 2, '' : step < 2 }">
            <div class="step-circle"><span>2</span></div>
            <div class="step-title">Ödeme Planı</div>
            <div class="step-bar-left"></div>
            <div class="step-bar-right"></div>
          </div>
          <div class="step" *ngIf="step === 2 || 3 || 4"
               [ngClass]="{'done': step > 3, 'editing' : step === 3, '' : step < 3 }">
            <div class="step-circle"><span>3</span></div>
            <div class="step-title">İnceleme</div>
            <div class="step-bar-left"></div>
            <div class="step-bar-right"></div>
          </div>
        </div>

      </div>
      <div class="step1" [ngClass]="step === 1 ? 'show-step' : 'hide-step'">
        <div class="form details-form">
          <form class="info-form" [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="row form-details">
              <div class="col-10 col-xs-11 form-details-1 ">
                <div class="info-holder">
                  <div class="row">
                    <p class="program-title primary-color">{{programData?.lmsProgramName}}</p>
                  </div>
                  </div>
                <div class=" info-holder right-info-holder">
                  <div class="last-row">
                    <div  class="col-sm " style="margin-bottom: 10px;">

                      <span class="info-primary">${{ programData?.fullPrice}}&nbsp;</span>
                   <!--   <span style="font-size:large;" *ngIf="programData?.discountedPrice" >&lt;!&ndash;s>${{programData?.fullPrice}}</s>&ndash;&gt;</span>-->
                    </div>
                  </div>
                  <div class="row">
                    @if(programData?.discountedPrice && programData?.discountAmount > 0){
                      <p  class="upfront-text">Ön ödeme yaparak ${{programData?.discountAmount}} tasarruf edin</p>
                    } <!--@else if (hasCoupon) {
                      <p   class="text-apple">Kuponlarla daha fazla tasarruf edin</p>
                    }-->
                    <p class="primary-color info last-row" style="font-weight: normal">Esnek ödeme planı mevcut</p>
                  </div>

                  <div class="row">
                    <div class="col-sm info">Program Başlangıç Tarihi <br>
                      <span class="info-value">{{programData?.startDate | date: 'mediumDate'}}</span>
                    </div>
                  </div>
<!--                  <div class="row" *ngIf="programData?.refundDate">-->
<!--                    <div class="col-sm info"><span class="gold-color">%100 </span>iade garantisi <br>-->
<!--                      <span class="info-value">{{programData?.refundDate | date: 'mediumDate'}} tarihine kadar</span>-->
<!--                    </div>-->
<!--                  </div>-->

                </div>
              </div>
            </div>
            <br>

            <div class="row form-details">

              <div class="col-9 personal-details">
                <p><b class="primary-color todo" style font-size="16">Kişisel bilgilerinizi girin</b></p>
                <br>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>İsim</mat-label>
                        <input formControlName="firstName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Soyisim</mat-label>
                        <input formControlName="lastName" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>E-posta Adresi</mat-label>
                        <input type="email" formControlName="email" (blur)="getPaymentPlansForUser($event)" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Telefon</mat-label>
                        <input formControlName="phoneNumber" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Bizi nereden duydunuz?</mat-label>
                        <mat-select formControlName="referralSource">
                          <mat-option selected>Birini Seçin</mat-option>
                          <mat-option value="email">E-posta</mat-option>
                          <mat-option value="facebook">Facebook</mat-option>
                          <mat-option value="google">Google</mat-option>
                          <mat-option value="instagram">Instagram</mat-option>
                          <mat-option value="linkedin">LinkedIN</mat-option>
                          <mat-option value="twitter">Twitter</mat-option>
                          <mat-option value="referred">Bir arkadaş veya meslektaş tarafından yönlendirildi</mat-option>
                          <mat-option value="other">Diğer</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-sm"></div>
                </div>
              </div>
              <!-- Ebeveyn detayları -->
              <div *ngIf="isForTeens" class="col-9 personal-details" formGroupName="parent">
                <p><b class="primary-color todo" style font-size="16">Ebeveyn bilgilerini girin</b></p>
                <br>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>İsim</mat-label>
                        <input formControlName="firstName" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Soyisim</mat-label>
                        <input formControlName="lastName" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>E-posta Adresi</mat-label>
                        <input type="email" formControlName="email" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field class="w-100" >
                        <mat-label>Telefon</mat-label>
                        <input formControlName="phoneNumber" [required]="isForTeens" matInput>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <button class=" next-button" type="submit" [disabled]="nextButtonDisabled">
              @if(nextButtonDisabled){
                <div class="button-spinner">
                  <mat-progress-spinner
                    class="example-margin"
                    [diameter]="20"
                    [mode]="'indeterminate'">
                  </mat-progress-spinner>
                </div>
              } @else {
                Sonraki
              }
            </button>
          </form>

          <div class="right-footer">
            <hr>
            <p class="footer-text">{{'Yardıma mı ihtiyacınız var? Bize şu adresten ulaşın: kayit@bilginomist.com'}}</p>
          </div>

        </div>
      </div>
      <div class="step2" [ngClass]="step === 2 ? 'show-step' : 'hide-step'">
        <div class="payment-plan">
          <p class="primary-color todo" style ="font-size:16px">Bir ödeme planı seçin</p>
          <mat-accordion>
            <div class="payment-plan-item-holder"  *ngFor="let item of paymentPlanItems">

              <mat-expansion-panel   hideToggle>
                <mat-expansion-panel-header  [ngClass]="paymentPlanIdSelected == item.priceId ? 'plan-selected' : ''" (click)="itemSelected(item)">
                  <mat-panel-title class="payment-panel-title">
                     <span class="payment-type">
                      {{ item.type === 'recurring' ? item.installments + ' Taksit' : 'Peşin ' }}</span>
                    <div class="title-second-row">
                      @if(item.type === 'recurring'){
                        <span class="discount-price">  ${{ item.receipt.installmentSummary.installmentPrice}}<span> her ay</span></span>

                      } @else {
                        <div class="price-holder">
                          <span class="base-price" *ngIf="item.receipt?.paymentSummary?.oneTimeDiscount && item.receipt.paymentSummary.oneTimeDiscount > 0">${{item.receipt.paymentSummary.basePrice}}</span>
                          <span class="discount-price">${{item.receipt.paymentSummary.subTotal}} <span> tek seferde öde</span></span>
                        </div>
                      }


                      <mat-chip-set>
                        <mat-chip class="discount-badge" *ngIf="item.receipt.paymentSummary?.oneTimeDiscount">
                      <span class="chip-content">    <span class="material-symbols-outlined light-icon">electric_bolt</span> ${{ item.receipt.paymentSummary.oneTimeDiscount }} Peşin indirimi
                      </span>
                        </mat-chip>
                        <mat-chip class="coupon-badge" *ngIf="item.allowCoupons && !hasCouponApplied(item)">
                          Kuponlar mevcut
                        </mat-chip>
                        <mat-chip *ngIf="coupons.length > 0 && hasCouponApplied(item)" class="discount-badge" >
                          <span class="chip-content"><span class="material-symbols-outlined">shoppingmode</span>${{couponAmount}} Kupon indirimi</span>
                        </mat-chip>
                      </mat-chip-set>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content-payment">
                  @if(item.type === 'one_time'){
                    <div class="content-panel-item">
                      <span>Temel fiyat</span>
                      <span class="price">${{reviewData?.receipt?.paymentSummary?.basePrice}}</span>
                    </div>
                    <div *ngIf="reviewData?.receipt?.paymentSummary?.oneTimeDiscount" class="content-panel-item">
                      <span>Peşin indirim</span>
                      <span class="price">- ${{ reviewData?.receipt?.paymentSummary?.oneTimeDiscount }} </span>
                    </div>
                    <button class="link-button" *ngIf="!showInputField && showIHaveCouponLink && !hasCouponApplied(item)" (click)="hideCouponLink()">Promosyon kodum var</button>
                    <div class="chips" *ngIf="showInputField">
                      <mat-form-field class="coupon-chip">
                        <mat-label>Kupon</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Kupon kodu">
                          <input
                            class="coupon-input"
                            [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                          >
                          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                        </mat-chip-grid>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="hide()">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                    <div *ngFor="let coupon of coupons">
                      <mat-chip *ngIf="coupon.priceId === item.priceId">
                        {{ coupon.name }}
                        <button matChipRemove (click)="remove(coupon)">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                    </div>

                    <div *ngIf="isValidCoupon && hasCouponApplied(item)" class="content-panel-item">
                      <span>Kupon</span>
                      <span class="price">- ${{ couponAmount }}</span>
                    </div>

                    <div  class="content-panel-item" style="flex-direction: column">
                      <div class="fee-items-holder">
                        <span>Ara toplam</span>
                        <span class="price">${{ isValidCoupon && hasCouponApplied(item) ? upfrontAmountAfterCoupon  : reviewData?.receipt?.paymentSummary?.subTotal }}</span>
                      </div>
                      <i class="excluding-fee">harçlar hariç</i>
                    </div>
                  }
                  @else{
                    <div class="content-panel-item coupon-section">
                      <div>
                        <span>Temel fiyat</span>
                        <span class="price">${{reviewData?.receipt?.installmentSummary?.basePrice}}</span>
                      </div>

                    </div>

                    <div class="content-panel-item">
                      <span>Taksitler</span>
                      <span class="price">{{reviewData?.receipt?.installmentSummary?.numberOfInstallments}}</span>
                    </div>
                    <div class="content-panel-item" >
                      <span>Taksit başına fiyat</span>
                      <span class="price">${{reviewData?.receipt?.installmentSummary?.installmentPrice}}</span>
                    </div>
                    <span class="sub-item-panel">Bugün Ödenen</span>
                    <div class="content-panel-item" style="flex-direction: column" >
                      <div class="fee-items-holder">
                        <span>İlk ayın ödemesi</span>
                        <span class="price">${{reviewData?.receipt?.installmentSummary?.installmentPrice}}</span>
                      </div>
                      <i class="excluding-fee">harçlar hariç</i>
                    </div>

                    <button class="link-button" *ngIf=" !showInputField && showIHaveCouponLink && !hasCouponApplied(item)" (click)="hideCouponLink()">Promosyon kodum var</button>
                    <div class="chips" *ngIf="showInputField">
                      <mat-form-field class="coupon-chip">
                        <mat-label>Kupon</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Kupon kodu">
                          <input
                            class="coupon-input"
                            [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                          >
                          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                        </mat-chip-grid>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="hide()">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-form-field>
                      </div>
                    <div *ngFor="let coupon of coupons">
                        <mat-chip *ngIf="coupon.priceId === item.priceId">
                          {{ coupon.name}}
                          <button matChipRemove (click)="remove(coupon)">
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip>
                    </div>

                    <div *ngIf="isValidCoupon && hasCouponApplied(item)" class="content-panel-item">
                      <span>Kupon</span>
                      <span class="price">- ${{ couponAmount }}</span>
                    </div>

                    <div *ngIf="isValidCoupon && hasCouponApplied(item)" class="content-panel-item">
                      <span>Bugün ödeyeceğiniz</span>
                      <span class="price">${{ installmentAmountAfterCoupon }}</span>
                    </div>

                  }
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
        <div class="action-buttons">
          <span class="back-button" (click)="backStep()"><u>Geri</u></span>
          <button class=" next-button" [ngClass]="selectedItem === null ? 'disabledButton' : '' " [disabled]="selectedItem === null" (click)="nextStep(3)">Sonraki</button>

        </div>

        <div class="right-footer">
          <hr>
          <p class="footer-text">{{'Yardıma mı ihtiyacınız var? Bize şu adresten ulaşın: kayit@bilginomist.com'}}</p>
        </div>
      </div>

      <div class="step3" [ngClass]="step === 3 ? 'show-step' : 'hide-step'">
        <div class="payment-plan" [ngClass]="stripeLoaded ? 'show-step' : 'hide-step'">
          @if(step === 3){
            <div class="pay-element">
              <app-payment-test (onPaymentChange)="onPaymentChange($event)"
                                (onPaymentDone)="onPaymentDone($event)"
                                [payment]="paymentData"
                                [makePayment]="makePayment" ></app-payment-test>
            </div>
            @if(paymentSummary){
              <div class="panel-content-holder">
                <div class="panel-content-payment">
                  @if (paymentSummary?.paymentType === 'one_time'){
                    <div class="content-panel-item">
                      <span>Ürün Fiyatı</span>
                      <span class="price">${{paymentSummary?.paymentSummary?.basePrice}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="paymentSummary?.paymentSummary?.oneTimeDiscount > 0">
                      <span>Peşin indirim</span>
                      <span class="price">- ${{paymentSummary?.paymentSummary?.oneTimeDiscount}}</span>
                    </div>
                    <div class="content-panel-item" *ngIf="coupons.length > 0">
                      <span>Kupon indirimi</span>
                      <span class="price">- ${{paymentSummary?.paymentSummary?.couponDiscount}}</span>
                    </div>
                    <div class="content-panel-item" >
                      <span>Ara toplam</span>
                      <span class="price">${{paymentSummary?.paymentSummary?.subTotal}}</span>
                    </div>
                    <div class="content-panel-item">
                      <span>İşlem ücreti</span>
                      <span class="price">${{paymentSummary?.paymentSummary?.fee}}</span>
                    </div>
                    <hr style="margin: 0;">
                    <div class="content-panel-item">
                      <span>Toplam</span>
                      <span class="price">${{paymentSummary?.paymentSummary?.total}}</span>
                    </div>

                  }
                  @else{
                    <div class="content-panel-item ">
                      <span>Ürün Fiyatı</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.basePrice}}</span>
                    </div>
                    <div class="content-panel-item ">
                      <span>Taksit Fiyatı</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.installmentPrice}}</span>
                    </div>
                    <div class="content-panel-item " *ngIf="coupons.length > 0">
                      <span>Kupon</span>
                      <span class="price">- ${{paymentSummary?.installmentSummary?.couponDiscount}}</span>
                    </div>
                    <div class="content-panel-item" >
                      <span>Ara toplam</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.subTotal}}</span>
                    </div>
                    <div class="content-panel-item" >
                      <span>İşlem ücreti</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.fee}}</span>
                    </div>
                    <hr style="margin: 0;">
                    <div class="content-panel-item">
                      <span>Toplam</span>
                      <span class="price">${{paymentSummary?.installmentSummary?.total}}</span>
                    </div>
                  }

                  <div class="form-check terms-conditions">
                    <input class="form-check-input" type="checkbox" id="defaultCheck2" [(ngModel)]="termsChecked">
                    <label class="form-check-label promotion-label" for="defaultCheck2">
                      <span class="privacy-checkbox"> <u (click)="openTermsConditions()">Şartlar ve Koşulları</u> okudum ve kabul ediyorum.</span>
                    </label>
                  </div>
                  <div class="action-buttons">
                    <span class="back-button" (click)="backStep()"><u>Geri</u></span>
                    <button type="button" mat-button class="next-button" [disabled]="!termsChecked || isPaying" [ngClass]="isPaying ? 'disabledButton spinner' : ''" (click)="pay()">{{isPaying ? ' &nbsp; &nbsp; ' : 'Öde'}}</button>
                  </div>
                  <div class="right-footer">
                    <hr>
                    <p class="footer-text">{{'Yardıma mı ihtiyacınız var? Bize şu adresten ulaşın: kayit@bilginomist.com'}}</p>
                  </div>

                </div>
              </div>
            }

          }
        </div>
        <div class="show-loader" [ngClass]="!stripeLoaded ? 'show-step' : 'hide-step'">
          <mat-spinner></mat-spinner>
        </div>

      </div>
      <div class="step4" [ngClass]="step === 4 ? 'show-step' : 'hide-step'">
        <div class="col-10">
          <div class="payment-confirmation">
            <p class="confirmation-title">Ödeme onayı <img src="assets/images/Vector.svg"></p>
            <p><span class="purplish">{{programData?.lmsProgramName}}</span> programına başarıyla kaydoldunuz. Makbuzunuz <u>{{customerData?.email}}</u> adresine gönderilecek. </p>
            <p>İlk adımı attınız. Şimdi size yolu gösterelim.</p>
          </div>
          <div class="onboarding">
            <p class="onboarding-title">Oryantasyon <img src="assets/images/Login.svg"></p>
            <p> Bizden bir doğrulama e-postası alacaksınız. Doğrulandıktan sonra, şifrenizi ayarlamak üzere yönlendirileceksiniz. Ardından öğrenme içeriğinize erişebileceksiniz.</p>
            <p>Kursta kullanılan diğer öğrenme uygulamalarına nasıl katılacağınıza dair daha fazla talimat içeren başka bir e-posta alacaksınız.</p>
            <br>
            <p class="purplish">Gelen kutunuzu kontrol edin.</p>
          </div>
          <div class="support">
            <p class="support-title">Destek <img src="assets/images/Agent.svg"></p>
            <p> Kayıt sürecinizle ilgili herhangi bir sorunuz varsa bize <span class="support-email"> {{'kayit@bilginomist.com'}}</span> adresinden ulaşabilirsiniz.</p>
            <p>Öğrenme içeriğinize erişimle ilgili teknik sorunlar için teknik destek ekibimizle <span class="support-email"> {{'kayit@bilginomist.com'}}</span> adresinden iletişime geçebilirsiniz.</p>
            <br>
            <p>Bu sayfayı şimdi kapatabilirsiniz.</p>
          </div>
        </div>
        <div class="right-footer">
          <hr>
          <p class="footer-text">{{'Yardıma mı ihtiyacınız var? Bize şu adresten ulaşın: kayit@bilginomist.com'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
