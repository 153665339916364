import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {provideNgxStripe} from "ngx-stripe";
import { provideAnimations } from '@angular/platform-browser/animations';
import {PaymentService} from "./services/payment.service";
import {RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service} from "ng-recaptcha";

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideNgxStripe(), provideAnimations(),
    PaymentService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Ld_ao4pAAAAALHmYRrcILju-_xvtf4p2c42tqPd" }
  ],

};
